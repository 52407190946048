// Per jaar anders of DEFAULT
export const BAM_VALUES = {
  2023: {
    1: 36000,
    2: 43000,
    3: 51500,
    4: 64500,
    5: 59500,
    6: 67500,
    7: 89500,
    8: 68500,
    9: 67500,
    10: 64000,
    11: 45500,
    12: 47500,
  },
  DEFAULT: { // 2024
    1: 60000,
    2: 65000,
    3: 65000,
    4: 75000,
    5: 80000,
    6: 85000,
    7: 95000,
    8: 95000,
    9: 95000,
    10: 85000,
    11: 80000,
    12: 75000,
  },
}
BAM_VALUES['2018'] = BAM_VALUES['2023']
BAM_VALUES['2019'] = BAM_VALUES['2023']
BAM_VALUES['2020'] = BAM_VALUES['2023']
BAM_VALUES['2021'] = BAM_VALUES['2023']
BAM_VALUES['2022'] = BAM_VALUES['2023']

// Per jaar anders of DEFAULT
export const TARGET_VALUES = {
  2023: {
    1: 40000,
    2: 45000,
    3: 70000,
    4: 80000,
    5: 80000,
    6: 90000,
    7: 100000,
    8: 100000,
    9: 100000,
    10: 90000,
    11: 60000,
    12: 65000,
  },
  DEFAULT: { // 2024
    1: 78000,
    2: 85000,
    3: 85000,
    4: 98000,
    5: 104000,
    6: 111000,
    7: 124000,
    8: 124000,
    9: 124000,
    10: 111000,
    11: 104000,
    12: 98000,
  },
}
TARGET_VALUES['2018'] = TARGET_VALUES['2023']
TARGET_VALUES['2019'] = TARGET_VALUES['2023']
TARGET_VALUES['2020'] = TARGET_VALUES['2023']
TARGET_VALUES['2021'] = TARGET_VALUES['2023']
TARGET_VALUES['2022'] = TARGET_VALUES['2023']
