export default (avg_price, daysInThisMonth, BAM_TARGET_LIST, month) => {
  const yearlyOmzet = Object.values(BAM_TARGET_LIST).reduce((a, b) => a + b, 0)

  const result = {
    omzet: {
      daily: 0,
      weekly: 0,
      monthly: 0,
      quarterly: 0,
      yearly: yearlyOmzet,
    },
    reservaties: {
      daily: 0,
      weekly: 0,
      monthly: 0,
      quarterly: 0,
      yearly: 0,
    },
  }

  const quarter = Math.floor((month - 1) / 3) + 1
  const quarterStartMonth = (quarter - 1) * 3 + 1

  // result.omzet.monthly = Math.ceil(yearlyOmzet / 12)
  result.omzet.quarterly = Math.ceil(BAM_TARGET_LIST[quarterStartMonth] + BAM_TARGET_LIST[quarterStartMonth + 1] + BAM_TARGET_LIST[quarterStartMonth + 2])
  result.omzet.monthly = Math.ceil(BAM_TARGET_LIST[month])
  result.omzet.daily = Math.ceil(result.omzet.monthly / daysInThisMonth)
  result.omzet.weekly = Math.ceil(result.omzet.daily * 7)

  result.reservaties.daily = Math.ceil(result.omzet.daily / avg_price)
  result.reservaties.weekly = Math.ceil(result.omzet.weekly / avg_price)
  result.reservaties.monthly = Math.ceil(result.omzet.monthly / avg_price)
  result.reservaties.quarterly = Math.ceil(result.omzet.quarterly / avg_price)
  result.reservaties.yearly = Math.ceil(result.omzet.yearly / avg_price)

  return result
}
